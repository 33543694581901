<template>
  <div class="page-works-detail">
    <Loading v-if="loading" :count="6"></Loading>
    <template v-else>
      <div class="works-detail__row">
        <div class="works-detail__content">
          <el-descriptions :column="1">
            <el-descriptions-item>
              <div
                style="
                  margin-left: -100px;
                  color: #ffc800;
                  font-size: 16px;
                  font-weight: 600;
                "
              >
                ⌷ 基础信息
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="所属竞赛">
              {{ detail.jsName || '-' }}
            </el-descriptions-item>
            <el-descriptions-item label="所属赛道">
              {{ detail.trackName || '-' }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('entries.name')">
              {{ detail.name || '-' }}
            </el-descriptions-item>
            <template v-if="detail.type === 1">
              <el-descriptions-item>
                <div
                  style="
                    margin-left: -100px;
                    color: #ffc800;
                    font-size: 16px;
                    font-weight: 600;
                  "
                >
                  ⌷ 作品信息
                </div>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.thumbnail')">
                <el-image
                  v-if="detail.thumbnail"
                  style="width: 100px; height: 100px"
                  :src="detail.thumbnail"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.title')">
                {{ detail.title || '-' }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.faceimg')">
                <el-image
                  v-if="detail.faceImg"
                  style="width: 100px; height: 100px"
                  :src="detail.faceImg"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.imgdetail')">
                <!-- <template v-if="detail.detailImg">
                  <el-image
                    v-for="(item, i) in detail.detailImg.split(',')"
                    :key="i"
                    style="width: 100px; height: 100px; margin-right: 10px"
                    :src="item"
                    :preview-src-list="detail.detailImg.split(',')"
                  ></el-image>
                </template> -->
                <el-image
                  v-if="detail.detailImg"
                  style="width: 100px; height: 100px"
                  :src="detail.detailImg"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.imgdetail')">
                <!-- <template v-if="detail.appendFile1">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/images/zip.png"
                  />
                  <br />
                  <el-button type="text" @click="download(detail.appendFile1)">
                    {{ getFileName(detail.appendFile1) }}下载
                  </el-button>
                </template> -->
                <el-image
                  v-if="detail.appendFile1"
                  style="width: 100px; height: 100px"
                  :src="detail.appendFile1"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.imgdetail')">
                <!-- <template v-if="detail.appendFile2">
                  <img
                    style="width: 60px; height: 60px"
                    src="@/assets/images/zip.png"
                  />
                  <br />
                  <el-button type="text" @click="download(detail.appendFile2)">
                    {{ getFileName(detail.appendFile2) }}下载
                  </el-button>
                </template> -->
                <el-image
                  v-if="detail.appendFile2"
                  style="width: 100px; height: 100px"
                  :src="detail.appendFile2"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
            </template>
            <template v-if="detail.type === 2">
              <el-descriptions-item :label="$t('entries.thumbnail')">
                <el-image
                  v-if="detail.thumbnail"
                  style="width: 100px; height: 100px"
                  :src="detail.thumbnail"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item :label="$t('entries.faceimg')">
                <el-image
                  v-if="detail.faceImg"
                  style="width: 100px; height: 100px"
                  :src="detail.faceImg"
                  :preview-src-list="previewImgs"
                ></el-image>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item label="说明图">
                <template v-if="detail.detailImg">
                  <el-image
                    v-for="(item, i) in detail.detailImg.split(',')"
                    :key="i"
                    style="width: 100px; height: 100px; margin-left: 10px"
                    :src="item"
                    :preview-src-list="previewImgs"
                  ></el-image>
                </template>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <div
                  style="
                    margin-left: -100px;
                    color: #ffc800;
                    font-size: 16px;
                    font-weight: 600;
                  "
                >
                  ⌷ 作品介绍
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="作品定位">
                {{ detail.opusFix || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="创新点">
                {{ detail.innovate || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="市场定位">
                {{ detail.marketFix || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="核心技术">
                {{ detail.coreTechnology || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="生产工艺">
                {{ detail.productionProcess || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="未来前景">
                {{ detail.futureProspects || '-' }}
              </el-descriptions-item>
              <el-descriptions-item>
                <div
                  style="
                    margin-left: -100px;
                    color: #ffc800;
                    font-size: 16px;
                    font-weight: 600;
                  "
                >
                  ⌷ 技术文档
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="3D模型">
                <a
                  v-if="detail.model"
                  :href="detail.model"
                  download
                  target="_blank"
                >
                  下载
                </a>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item label="结构文档">
                <a
                  v-if="detail.structureDocument"
                  :href="detail.structureDocument"
                  download
                  target="_blank"
                >
                  下载
                </a>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item label="作品视频">
                <a
                  v-if="detail.opusVideo"
                  :href="detail.opusVideo"
                  download
                  target="_blank"
                >
                  下载
                </a>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item label="其他材料">
                <a
                  v-if="detail.otherFile"
                  :href="detail.otherFile"
                  download
                  target="_blank"
                >
                  下载
                </a>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <div
                  style="
                    margin-left: -100px;
                    color: #ffc800;
                    font-size: 16px;
                    font-weight: 600;
                  "
                >
                  ⌷ 专利情况
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="专利情况">
                <span v-if="detail.patentStatus === 1">已申请</span>
                <span v-if="detail.patentStatus === 2">申请中</span>
                <span v-if="detail.patentStatus === 3">未申请</span>
              </el-descriptions-item>
              <el-descriptions-item
                label="专利类型"
                v-if="detail.patentStatus === 1 && detail.patentType"
              >
                <span v-for="item in detail.patentType.split(',')" :key="item">
                  {{ patentTypeMap[item] }}
                </span>
              </el-descriptions-item>
              <el-descriptions-item
                label="证明材料"
                v-if="[1, 2].includes(detail.patentStatus)"
              >
                <a
                  v-if="detail.proveFile"
                  :href="detail.proveFile"
                  download
                  target="_blank"
                >
                  下载
                </a>
                <span v-else>-</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <div
                  style="
                    margin-left: -100px;
                    color: #ffc800;
                    font-size: 16px;
                    font-weight: 600;
                  "
                >
                  ⌷ 运营情况
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="上市情况">
                <span v-if="detail.onMarket">已上市</span>
                <span v-else>未上市</span>
              </el-descriptions-item>
              <template v-if="detail.onMarket">
                <el-descriptions-item label="上市情况">
                  {{ detail.onMarketTime || '-' }}
                </el-descriptions-item>
                <el-descriptions-item label="上市售价">
                  {{ detail.price || '-' }}
                </el-descriptions-item>
              </template>
              <template v-else>
                <el-descriptions-item label="预期上市时间">
                  {{ detail.onMarketTime || '-' }}
                </el-descriptions-item>
                <el-descriptions-item label="预期上市售价">
                  {{ detail.price || '-' }}
                </el-descriptions-item>
              </template>
            </template>
          </el-descriptions>
        </div>
        <div class="works-detail__score">
          <div class="works-detail__rules" v-if="currentRecordFromJudge">
            <div class="works-detail__rules-inner">
              <template v-if="currentRecordFromJudge.scoreRule">
                <p
                  style="line-height: 20px; min-height: 20px; color: #333"
                  v-for="(item, i) in currentRecordFromJudge.scoreRule.split(
                    '\n'
                  )"
                  :key="i"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ currentRecordFromJudge.scoreRule }}
              </template>
            </div>
          </div>
          <p
            class="works-detail__status"
            v-if="judgeInfo && judgeInfo.type === 1"
          >
            当前状态
            <span style="color: green" v-if="detail.firstScore === 1">
              入围
            </span>
            <span style="color: red" v-else-if="detail.firstScore === 2">
              不入围
            </span>
            <span v-else-if="detail.firstScore === 3">待定(加入比较)</span>
            <span v-else>待审核</span>
          </p>
          <p
            class="works-detail__status"
            v-if="judgeInfo && judgeInfo.type === 2"
          >
            当前评分
            <span>{{ (detail && detail.score) || '-' }}</span>
          </p>
          <pro-form
            label-width="120px"
            label-suffix=":"
            :model="scoreModel"
            :fields="scoreFields"
            :rules="scoreRules"
            :submitter="{
              submitButtonProps: {
                scoreLoading
              },
              submitText:
                detail && (detail.firstScore || detail.score) ? '修改' : '评审',
              resetText: '返回'
            }"
            @submit="scoreSubmit"
            @reset="scoreReset"
          ></pro-form>
          <div class="works-detail__actions">
            <el-button type="text" @click="junmp(1)">上一页</el-button>
            <el-button type="text" @click="junmp(2)">下一页</el-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from '@/components/loading'
import { getFileName, saveFile } from '@/utils/tools'
import * as ConstantRouter from '@/constant/router'
import { localStore } from '@/utils/store'
import { mapState } from 'vuex'

const patentTypeMap = {
  1: '实用专利类型',
  2: '外观设计类型',
  3: '发明专利类型',
  4: '软著/著作类型',
  5: '商标类型'
}

export default {
  components: {
    Loading
  },
  data() {
    return {
      patentTypeMap,
      loading: true,
      id: this.$route.params.id,
      jsId: this.$route.params.jsId,
      detail: null,
      scoreModel: {
        firstScore: undefined,
        score: '',
        remark: ''
      },
      scoreRules: {
        score: [{ required: true, message: '请输入分数', trigger: 'change' }],
        firstScore: [
          { required: true, message: '请选择是否通过', trigger: 'change' }
        ]
      },
      scoreLoading: false
    }
  },
  computed: {
    ...mapState({
      currentRecordFromJudge(state) {
        return state.currentRecordFromJudge
      },
      judgeInfo(state) {
        return state.judgeInfo
      }
    }),
    scoreFields() {
      return [
        ...(this.judgeInfo?.type === 1
          ? [
              {
                type: 'radio-group',
                formItemProps: {
                  label: '初审',
                  prop: 'firstScore'
                },
                children: [
                  {
                    label: 1,
                    text: this.detail.firstScore ? '入围' : '通过'
                  },
                  {
                    label: 2,
                    text: this.detail.firstScore ? '不入围' : '不通过'
                  },
                  {
                    label: 3,
                    text: '待定(加入比较)'
                  }
                ]
              },
              {
                type: 'textarea',
                formItemProps: {
                  label: '备注',
                  prop: 'remark'
                },
                style: {
                  width: '360px'
                }
              }
            ]
          : []),
        ...(this.judgeInfo?.type === 2
          ? [
              {
                type: 'input',
                formItemProps: {
                  label: '分数',
                  prop: 'score'
                },
                style: {
                  width: '360px'
                }
              },
              {
                type: 'textarea',
                formItemProps: {
                  label: '备注',
                  prop: 'remark'
                },
                style: {
                  width: '360px'
                }
              }
            ]
          : [])
      ]
    },
    firstScoreText() {
      if (this.detail.firstScore === 1) {
        return '入围'
      }
      if (this.detail.firstScore === 2) {
        return '不入围'
      }
      if (this.detail.firstScore === 3) {
        return '待定(加入比较)'
      }
      return '待审核'
    },
    previewImgs() {
      if (this.detail.type === 1) {
        return [
          this.detail.thumbnail,
          this.detail.faceImg,
          this.detail.detailImg,
          this.detail.appendFile1,
          this.detail.appendFile2
        ]
      } else if (this.detail.type === 2) {
        return [
          this.detail.thumbnail,
          ...(this.detail.detailImg ? this.detail.detailImg.split(',') : [])
        ]
      } else {
        return []
      }
    }
  },
  watch: {
    id: {
      handler() {
        this.fetchDetail()
      },
      immediate: true
    }
  },
  methods: {
    getFileName(path) {
      return getFileName(path)
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsOpus.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
      this.scoreModel = {
        firstScore: this.detail.firstScore,
        score: this.detail.score,
        remark: this.detail.remark
      }
    },
    download(path) {
      saveFile(path)
    },
    async scoreSubmit() {
      if (this.judgeInfo.type === 1) {
        this.addFirst()
        // 初审
        return
      }
      if (this.judgeInfo.type === 2) {
        // 复审
        if (this.detail.score) {
          this.scoreUpdate()
        } else {
          this.scoreAdd()
        }
      }
    },
    async addFirst() {
      this.scoreLoading = true
      const [err] = await this.$serve.jsOpusScore.addFirst({
        data: {
          id: this.detail.id,
          opusId: this.detail.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          firstScore: this.scoreModel.firstScore,
          remark: this.scoreModel.remark
        }
      })
      this.scoreLoading = false
      if (err) return
      this.$message.success('初审成功')
      this.scoreVisible = false
      // this.fetchDetail()
      this.junmp(2)
    },
    async scoreAdd() {
      this.scoreLoading = true
      const [err] = await this.$serve.jsOpusScore.add({
        data: {
          id: this.detail.id,
          opusId: this.detail.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          score: this.scoreModel.score,
          remark: this.scoreModel.remark
        }
      })
      this.scoreLoading = false
      if (err) return
      this.$message.success('打分成功')
      this.scoreVisible = false
      // this.fetchDetail()
      this.junmp(2)
    },
    async scoreUpdate() {
      this.scoreLoading = true
      const [err] = await this.$serve.jsOpusScore.update({
        data: {
          id: this.detail.id,
          opusId: this.detail.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          score: this.scoreModel.score,
          remark: this.scoreModel.remark
        }
      })
      this.scoreLoading = false
      if (err) return
      this.$message.success('修改打分成功')
      this.scoreVisible = false
      // this.fetchDetail()
      this.junmp(2)
    },
    scoreReset() {
      this.$router.push({
        name: ConstantRouter.JUDGE_REVIEW
      })
    },
    async junmp(type) {
      if (this.judgeInfo.type === 1) {
        this.getOpusUnScoreFirst(type)
      }
      if (this.judgeInfo.type === 2) {
        this.getOpusUnScore(type)
      }
    },
    async getOpusUnScore(type) {
      const [err, res] = await this.$serve.jsOpus.getOpusUnScore({
        data: {
          recordId: this.detail.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          type,
          track: this.$route.query.track
        }
      })
      if (err) return
      if (!res) {
        this.$message.warning('没有了')
        return
      }
      this.$router.push({
        name: ConstantRouter.JUDGE_REVIEW_DETAIL,
        params: {
          id: res.id
        },
        query: {
          track: this.$route.query.track
        }
      })
      this.id = res.id
      this.scoreModel = {
        firstScore: undefined,
        score: '',
        remark: ''
      }
    },
    async getOpusUnScoreFirst(type) {
      const [err, res] = await this.$serve.jsOpus.getOpusUnScoreFirst({
        data: {
          recordId: this.detail.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          type,
          track: this.$route.query.track
        }
      })
      if (err) return
      if (!res) {
        this.$message.warning('没有了')
        return
      }
      this.$router.push({
        name: ConstantRouter.JUDGE_REVIEW_DETAIL,
        params: {
          id: res.id
        },
        query: {
          track: this.$route.query.track
        }
      })
      this.id = res.id
      this.scoreModel = {
        firstScore: undefined,
        score: '',
        remark: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-works-detail {
  ::v-deep {
    .el-descriptions-item__container {
      min-height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__label {
      width: 120px;
      text-align: right;
      flex-shrink: 0;
    }
  }

  .works-detail__row {
    display: flex;
    border: 2px solid #eee;
  }

  .works-detail__content {
    flex: 1;
  }

  .works-detail__status {
    padding: 20px 10px 20px 10px;
    margin-left: 60px;
    margin-bottom: 10px;
    display: flex;
    background: #f5f5f5;
    color: #333;
    span {
      margin-left: 20px;
      flex: 1;
      text-align: center;
    }
  }
  .works-detail__score {
    width: 522px;
    margin-left: 20px;
    border-left: 2px solid #eee;
    display: flex;
    flex-direction: column;
    padding: 0 40px 20px 0;
  }
  .works-detail__actions {
    padding: 0 0 0 120px;
    display: flex;
    justify-content: space-between;
  }

  .works-detail__rules {
    flex: 1;
    min-height: 200px;
    margin: 20px 0 20px 60px;
    background: #f5f5f5;
    position: relative;
  }

  .works-detail__rules-inner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 20px;
    overflow-y: auto;
  }
}
</style>
